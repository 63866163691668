// vue
import { ref, reactive, watch, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// services
import Marketplace from '@services/marketplace';
import LocalStorage from '@services/ts/localStorage';

// naive-ui
import {
    useMessage } from 'naive-ui';

export default function () {
    const gl = useGl();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // i18n
    const { t } = useI18n();

    // router
    const route = useRoute();
    const router = useRouter();

    // vars
    const { _ } = window;
    const init = ref(false);
    const page = ref(1);
    const algos = ref();
    const exchanges = ref();

    // storage filters
    const storeFilterKey = `___filters_marketplace--${route.name}`;
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            exchanges: [ -1 ],
            algos: [ -1 ],
            last_conf_changed_date: [ -1 ],
            first_symbol: [ -1 ],
            last_symbol: [ -1 ],
            ...(
                route.name === 'marketplace.buy.free'
                    ? {
                        max_price: 0.0,
                        force_all: true }
                    : null
            ),
        }, {
            perPage: 10,
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const resetFilters = () => {
        filters.filters.exchanges = [ -1 ];
        filters.filters.algos = [ -1 ];
        filters.filters.last_conf_changed_date = [ -1 ];
        filters.filters.first_symbol = [ -1 ];
        filters.filters.last_symbol = [ -1 ];

        getData();
    };

    const type = computed(() => route.name === 'marketplace.buy' ? 'marketplace.buy' : 'marketplace.buy.free');

    const filtersChanged = computed(() => {
        return filters.filters.exchanges[0] !== -1
            || filters.filters.algos[0] !== -1
            || filters.filters.last_conf_changed_date[0] !== -1
            || filters.filters.first_symbol[0] !== -1
            || filters.filters.last_symbol[0] !== -1;
    });

    const localLoading = computed(() => gl.loading || marketplace.loading);
    const actions = computed(() => [route.name === 'marketplace.buy.free' ? 'marketplace_free_buy__review' : 'marketplace_Buy__review']);

    const showModal = computed(() => !!route.params.bot_id || !!route.params.free_bot_id);

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.marketplace.filters_marketplace_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.marketplace.filters_marketplace_algo_label,
            refs: marketplace.algos,
            filter: 'filters.algos',
            seeable: filters.filters.algos[0] !== -1,
        }, {
            mode: 'radio_group',
            title: refs.localization.filters.marketplace.filters_marketplace_configuration_without_changes_label,
            refs: marketplace.periods,
            value: filters.filters.last_conf_changed_date,
            filter: 'filters.last_conf_changed_date',
            seeable: filters.filters.last_conf_changed_date[0] !== -1,
        }, {
            mode: 'filter_coins',
            title: refs.localization.filters.marketplace.filters_marketplace_first_coin_label,
            refs: refs.currencies,
            value: filters.filters.first_symbol,
            filter: 'filters.first_symbol',
            seeable: filters.filters.first_symbol[0] !== -1,
        }, {
            mode: 'filter_coins',
            title: refs.localization.filters.marketplace.filters_marketplace_second_coin_label,
            refs: refs.currencies,
            value: filters.filters.last_symbol,
            filter: 'filters.last_symbol',
            seeable: filters.filters.last_symbol[0] !== -1,
        },
    ]);

    watch(() => refs.lang, async () => {
        await getData();
    });

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && marketplace.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem(storeFilterKey, value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update || canUpdate)
            getData();
    };

    const getData = async () => {
        gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        try {
            const prepare = await Marketplace.buyGet({
                pager: filters,
            });

            if (marketplace.buyGetNotEmpty) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        marketplace.buyGet[key] = prepare.data[key];
                    }
                });
            } else {
                marketplace.buyGet = prepare.data;
            }

            if (marketplace.buyGet.records.length)
                marketplace.buyGet.records.push({
                    type: 'footer',
                    ...marketplace.buyGet.totals,
                });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        getData();
    };

    const parsed = computed(() => {
        var arr = marketplace.localization['marketplace/buy/info'].split(/></g);

        //split removes the >< so we need to determine where to put them back in.
        for(var i = 0; i < arr.length; i++){
            if(arr[i].substring(0, 1) != '<'){
                arr[i] = '<' + arr[i];
            }

            if(arr[i].slice(-1) != '>'){
                arr[i] = arr[i] + '>';
            }
        }

        return arr;
    });

    onMounted( async () => {
        // cache
        // if (!marketplace.buyGet)
        await getData();

        init.value = true;
    });

    return {
        gl,
        refs,
        page,
        init,
        type,
        algos,
        router,
        actions,
        filters,
        parsed,
        showModal,
        exchanges,
        refFilters,
        marketplace,
        localLoading,
        filtersChanged,
        getData,
        sortColumn,
        changeFilter,
        resetFilters,
    };
}